<template>
  <v-card class="elevation-12">
    <v-toolbar dark color="red">
      <v-toolbar-title> {{ $t("ResetPassword") }} </v-toolbar-title>
    </v-toolbar>
    <v-card-text>
      <v-form v-model="valid" ref="reset-password-form" lazy-validation>
        <v-text-field
          v-model="credentials.email"
          prepend-icon="mdi-account"
          name="email"
          :label="$t('Email')"
          type="email"
          color="dark"
          :rules="[filterRules.email, filterRules.required]"
        ></v-text-field>
        <v-text-field
          v-model="credentials.new_password"
          prepend-icon="mdi-lock"
          name="password"
          :label="$t('NewPassword')"
          type="password"
          color="dark"
          :rules="[filterRules.required, filterRules.min8]"
        ></v-text-field>
        <v-text-field
          v-model="credentials.password_confirmation"
          prepend-icon="mdi-lock"
          name="password_confirmation"
          :label="$t('PasswordConfirmation')"
          type="password"
          color="dark"
          :rules="[
            filterRules.required,
            filterRules.passwordConfirmation,
            filterRules.min8,
          ]"
        ></v-text-field>
        <div class="reset-password-actions">
          <v-btn
            :loading="isSendingLink"
            class="submit-btn"
            dark
            color="dark"
            @click="attemptSubmit"
          >
            {{ $t("Submit") }}
          </v-btn>
          <v-btn
            class="submit-btn"
            dark
            color="red darken-3"
            @click="goTo('LoginPage')"
          >
            {{ $t("Cancel") }}
          </v-btn>
        </div>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import Vue from "vue";

export default {
  name: "ForgotPasswordForm",
  data() {
    return {
      valid: false,
      isSendingLink: false,
      credentials: {
        email: null,
        new_password: null,
        password_confirmation: null,
      },
    };
  },
  computed: {
    filterRules() {
      let rules = Vue.formHelper.filterRules();
      return {
        ...rules,
        passwordConfirmation: (value) => {
          if (value && value !== this.credentials.new_password) {
            return this.$t("PasswordDontMatch");
          }
          return true;
        },
      };
    },
  },
  methods: {
    attemptSubmit() {
      // to validate if none of the inputs were changed
      let validation = this.$refs["reset-password-form"].validate();

      if (this.valid && validation) {
        this.isSendingLink = true;

        let token = Vue.urlHelper.extractQuery()['token'];

        let data = {
          ...this.credentials,
          password: this.credentials.new_password,
          token,
        }

        this.$store
          .dispatch("auth/resetPassword", data)
          .then((success) => {
            this.$emit("password-changed", true);
          })
          .catch((failure) => {
            if (failure.response) {
              let message = "";
              if (failure.response.data.error) {
                message = failure.response.data.error.message;
              } else {
                message = failure.response.data.message;
              }
              this.$root.$emit("notify-error", this.$t(message));
            }
            this.$emit("password-change-error", true);
          })
          .finally(() => {
            setTimeout(() => {
              this.isSendingLink = false;
            }, 500);
          });
      }
    },
    goTo(routeName) {
        var searchObj = Vue.urlHelper.extractQuery();
        if (searchObj["company_id"]) {
          this.$router.push({name: routeName, query: {company_id: searchObj["company_id"]}});
        } else {
          this.$router.push({name: routeName});
        }
    }
  },
};
</script>

<style lang="scss" scoped>
.submit-btn {
  margin: 0px 10px;
  padding: 5px 20px;
}
.login-link {
  text-decoration: none;
  font-weight: bold;
  @media (max-width: 767px) {
    display: block;
    margin: 10px auto;
  }
}
.forgot-password-actions {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  @media (max-width: 767px) {
    display: block;
    text-align: center;
  }
}
</style>