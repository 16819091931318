<template>
  <div>
    <polygon-background
      :styles="{
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
      }"
      :animateHexagons.sync="animatePolygons"
    />
    <v-container fluid class="reset-pw-page" fill-height>
      <v-row no-gutters justify="center" align="center">
        <v-col cols="12" md="6">
          <reset-password-form v-show="!passwordChanged" @password-changed="passwordChangedSuccess" />
          <v-alert
            prominent
            type="success"
            v-show="passwordChanged"
            class=" mb-7 rounded-0 darken-1"
          >
            <v-row align="center">
              <v-col class="grow">
                {{ $t("PasswordChanged") }}
              </v-col>
              <v-col class="shrink">
                <v-btn @click="goTo('LoginPage')">
                    {{ $t('LoginFormHeader') }}
                </v-btn>
              </v-col>
            </v-row>
          </v-alert>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import Vue                from 'vue';
import ResetPasswordForm  from "../../components/Form/Auth/ResetPassword";
import PolygonBackground  from "../../components/Util/PolygonBackground";

export default {
  name: "LoginPage",
  components: {
    ResetPasswordForm,
    PolygonBackground,
  },
  data() {
    return {
      animatePolygons: false,
      passwordChanged: false,
    };
  },
  methods: {
      passwordChangedSuccess() {
          this.passwordChanged = true;
      },
      goTo(routeName) {
          var searchObj = Vue.urlHelper.extractQuery();
          if (searchObj["company_id"]) {
            this.$router.push({name: routeName, query: {company_id: searchObj["company_id"]}});
          } else {
            this.$router.push({name: routeName});
          }
      }
  },
};
</script>

<style lang="scss" scoped>
.reset-pw-page {
  height: 80vh;
}
</style>
